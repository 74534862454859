import React from "react";

export function PrivacyPolicies() {
return (
<div  style={{height: "100vh"}}>
    <iframe
            src="privacy_policies.html"
            width="100%"
            height="100%"
            style={{ border: "none" }}
    title="Privacy Policies"
    ></iframe>
</div>
);
};
