import './App.css';
import {Home} from "./home/Home";
import {EmailConfirmation} from "./email_confirmation/EmailConfirmation";
import {ResetPassword} from "./reset_password/ResetPassword";
import { Routes, Route } from "react-router-dom";
import { DeleteAccount } from './delete_account/DeleteAccount';
import { PrivacyPolicies } from './privacy_policies/PrivacyPolicies';
import { TermsAndConditions } from './terms_and_conditions/TermsAndConditions';

function App() {
return (
   <Routes>
        <Route path="/" element={<Home />}/>
        <Route index element={<Home />} />
        <Route path="delete-account" element={<DeleteAccount />} />
        <Route path="email-confirmation" element={<EmailConfirmation />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="privacy_policies" element={<PrivacyPolicies />} />
        <Route path="terms_and_conditions" element={<TermsAndConditions />} />
    </Routes>
  );
}

export default App;
