import React from "react";

export function TermsAndConditions() {
return (
<div  style={{height: "100vh"}}>
    <iframe
            src="terms_and_conditions.html"
            width="100%"
            height="100%"
            style={{ border: "none" }}
    title="Terms and Conditions"
    ></iframe>
</div>
);
};
